<template>
  <div>
    <v-card
      flat
      style="margin: 10px; background-color: transparent"
      width="100%"
      @click="$router.push(`/login`)"
    >
      <div :elevation="hover ? 24 : 6" flat class="text-center mb-2">
        <v-img :aspect-ratio="1 / 1" class="media" :src="itemPic">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
      <p v-if="item.isAvailable" style="color: green">
        {{ $t("itemCard.available") }}
      </p>
      <p v-else style="color: red">{{ $t("itemCard.unavailable") }}</p>
      <div class="mt-n3">
        <v-tooltip top class="block">
          <template v-slot:activator="{ on, attrs }">
            <div
              style="cursor: default"
              class="text font-weight-regular"
              v-bind="attrs"
              v-on="on"
            >
              {{
              item.baseItem.name
              }}
            </div>
          </template>
          <span>{{
         item.baseItem.name
          }}</span>
        </v-tooltip>
      </div>
    </v-card>
    <v-flex v-for="i in 15" :key="i">
      <v-card
        flat
        style="margin: 10px; background-color: transparent"
        class="mx-2"
        min-width="135"
      />
    </v-flex>
  </div>
</template>

<script>
export default {
  name: "ItemCard",
  props: ["item"],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    itemPic() {
      return this.item?.images[0]?.url
        ? `https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${
            this.item?.images[0]?.url ?? ""
          }`
        : require("../../../assets/images/img-fallback.png");
    },
  },

  methods: {},
};
</script>

<style scoped>
.media {
  border-radius: 3%;
}
.media:hover {
  box-shadow: 0 0 3pt 2pt #000000;
  border-radius: 3%;
  cursor: pointer;
}
.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  width: 100%;
  position: absolute;
}
.block {
  display: block !important;
  height: 24px;
}
</style>